$(document).ready(() => {
  let getRowDataAdded = 0;
  if($("#characteristic_count").length != 0) {
    getRowDataAdded = 25 - parseInt(document.getElementById('characteristic_count').value);
  }
  
  let c_count = getRowDataAdded;
  $(document).on('click', '.add_muliple_row', function(e) {
    if(c_count>1)
    {
      c_count--;
      var $parent;
      e.preventDefault();
      $parent = $(this).closest('div.multiple_check');
      $parent.find('button').addClass('createdDiv');
      this.blur();
      return $parent.after('<div class="multiple_check">' + $parent.html() + '</div>');
    }
    else {
      this.blur();
      return false;
    }
  });
    
  $(document).on('click', '.delete_row', function(e) {
    if( c_count< getRowDataAdded) {
      c_count++;
      var $parent;
      e.preventDefault();
      $parent = $(this).closest('div.multiple_check');
      $parent.find('button').removeClass('createdDiv');
      this.blur();
      $(".small-move-tooltip-data").remove()
      if ($('div.multiple_check').length > 1) {
      return $parent.remove();
      }
    }
    else {
      this.blur();
      $(".small-move-tooltip-data").remove()
      return false;
    }
  })
  
  const selectedAllCheckedDataGroupTag = document.querySelectorAll('.organizationgroup-checkbox1:checked');
  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedDataGroupTag, 'organizationgroup', 'Organization group', 1);

  const selectedAllCheckedCompanyTag = document.querySelectorAll('.organizationname-checkbox1:checked');
  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedCompanyTag, 'organizationname', 'Organization name', 1);

  const selectedAllCheckedDataRegionTag = document.querySelectorAll('.region-checkbox1:checked');
  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedDataRegionTag, 'region', 'Region', 1);

  const selectedAllCheckedData1Tag = document.querySelectorAll('.datalist-checkbox1:checked');
  showCheckedDataAsListsDetails1(selectedAllCheckedData1Tag, 'datalist', 'Data list', 1);

  const selectedAllCheckedDataGroup2Tag = document.querySelectorAll('.organizationgroupcomgrp-checkbox1:checked');
  showCheckedDataAsListsDetails1(selectedAllCheckedDataGroup2Tag, 'organizationgroupcomgrp', 'Organization Group', 2);

  const selectedAllCheckedCompany2Tag = document.querySelectorAll('.organizationnamecomgrp-checkbox1:checked');
  showCheckedDataAsListsDetails1(selectedAllCheckedCompany2Tag, 'organizationnamecomgrp', 'Organization Name', 2);

  const selectedAllCheckedDataRegion2Tag = document.querySelectorAll('.regioncomgrp-checkbox1:checked');
  showCheckedDataAsListsDetails1(selectedAllCheckedDataRegion2Tag, 'regioncomgrp', 'Region', 2);

  const selectedAllCheckedData2Tag = document.querySelectorAll('.datalistcomgrp-checkbox1:checked');
  showCheckedDataAsListsDetails1(selectedAllCheckedData2Tag, 'datalistcomgrp', 'Data list', 2);

  $('.organizationgroup-checkbox1').on('change', () => {
    showOrganisationGrpBasedDetails();
    let selectedAllCheckedDataGroupTag1 = $('.organizationgroup-checkbox1');
    let checked=true
    selectedAllCheckedDataGroupTag1.each(function() {
      if (!$(this).prop('checked')) {
        checked=false;
        return false
      }
    });
    if (checked){
      selectedAllCheckedDataGroupTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', true);
    }
    else {
      selectedAllCheckedDataGroupTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', false);
    }
  });

  $('.region-main').delegate('.region-checkbox1', 'click', () => {
    showRegionBasedDetails();
    let selectedAllCheckedDataRegionTag1 = $('.region-checkbox1');
    let checked=true
    selectedAllCheckedDataRegionTag1.each(function() {
      if (!$(this).prop('checked')) {
        checked=false;
        return false
      }
    });
    if (checked){
      selectedAllCheckedDataRegionTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', true);
    }
    else {
      selectedAllCheckedDataRegionTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', false);
    }
  });

  $('.organisation-main').delegate('.organizationname-checkbox1', 'click', () => {
    showCompanyBasedDetails();
    let selectedAllCheckedCompanyTag1 = $('.organizationname-checkbox1');
    let checked=true
    selectedAllCheckedCompanyTag1.each(function() {
      if (!$(this).prop('checked')) {
        checked=false;
        return false
      }
    });
    if (checked){
      selectedAllCheckedCompanyTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', true);
    }
    else {
      selectedAllCheckedCompanyTag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', false);
    }
  });

  $('.datalist-main').delegate('.datalist-checkbox1', 'click', () => {
    const selectedAllCheckedData1Tag = document.querySelectorAll('.datalist-checkbox1:checked');
    showCheckedDataAsListsDetails1(selectedAllCheckedData1Tag, 'datalist', 'Data list', 1);
    let selectedAllCheckedData1Tag1 = $('.datalist-checkbox1');
    let checked=true
    selectedAllCheckedData1Tag1.each(function() {
      if (!$(this).prop('checked')) {
        checked=false;
        return false
      }
    });
    if (checked){
      selectedAllCheckedData1Tag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', true);
    }
    else {
      selectedAllCheckedData1Tag1.parents(".exp-dropdown-select").find(".select-all-check").prop('checked', false);
    }
  });

  $('.organizationgroupcomgrp-checkbox1').on('change', () => {
    showOrganisationGrp2BasedDetails();
  });

  $('.organisation2-main').delegate('.organizationnamecomgrp-checkbox1', 'click', () => {
    showCompany2BasedDetails();
  });

  $('.region2-main').delegate('.regioncomgrp-checkbox1', 'click', () => {
    showRegion2BasedDetails();
  });

  $('.datalist2-main').delegate('.datalistcomgrp-checkbox1', 'click', () => {
    const selectedAllCheckedData2Tag = document.querySelectorAll('.datalistcomgrp-checkbox1:checked');
    showCheckedDataAsListsDetails1(selectedAllCheckedData2Tag, 'datalistcomgrp', 'Data list', 2);
  });

  $('.clearSelectedData1').on('click', () => {
    $.get('/ajax/ag_v2_filters',
      (result) => {
        $('.input-checkbox-data').prop('checked', false);
        $('#organisation-group_filtered_area').empty();
        if ($('.exp-drop3').length > 0) {
          $('#region_set1').remove();
          ul_string = '<ul class="m-0" id="region_set1">';
          $.each(result.gd_regions, (k, v) => {
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="region_set1[]" class="region${k} region-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#region_set1_append').append(ul_string);
        }
        $('#region_filtered_area').empty();
        if ($('.exp-drop2').length > 0) {
          $('#company_set1').remove();

          ul_string = '<ul class="m-0" id="company_set1">';
          $.each(result.gd_companies, (k, v) => {
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set1[]" class="organizationname${k} organizationname-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#company_set1_append').append(ul_string);
        }
        $('#organization_filtered_area').empty();
        if ($('.exp-drop4').length > 0) {
          $('#data_list_set1').remove();

          ul_string = '<ul class="m-0" id="data_list_set1">';
          $.each(result.gd_data_lists, (k, v) => {
            if (result.gd_checked_data_lists.indexOf(parseInt(k)) >= 0) {
              var check = 'checked="checked"';
            } else {
              var check = ' ';
            }
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set1_ids[]" class="datalist${k} datalist-checkbox1 input-checkbox-data"${check}value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#datalist_set1_append').append(ul_string);
        }
        $('#datalist1_filtered_area').empty();
        const selectedAllCheckedData1Tag = document.querySelectorAll('.datalist-checkbox1:checked');
        showCheckedDataAsListsDetails1(selectedAllCheckedData1Tag, 'datalist', 'Data list', 1);
      });
  });

  $('.clearSelectedData2').on('click', () => {
    $.get('/ajax/ag_v2_filters',
      (result) => {
        $('.inputcomgrp-checkbox-data').prop('checked', false);
        $('#organisation-group_filtered_area2').empty();
        $('#datalist2_filtered_area').empty();
        if ($('.exp-drop3').length > 0) {
          $('#region_set2').remove();
          ul_string = '<ul class="m-0" id="region_set2">';
          $.each(result.gd_regions, (k, v) => {
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="region_set2[]" class="regioncomgrp${k} regioncomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#region_set2_append').append(ul_string);
        }
        $('#region_filtered_area2').empty();
        if ($('.exp-drop2').length > 0) {
          $('#company_set2').remove();

          ul_string = '<ul class="m-0" id="company_set2">';
          $.each(result.gd_companies, (k, v) => {
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set2[]" class="organizationnamecomgrp${k} organizationnamecomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#company_set2_append').append(ul_string);
        }
        $('#organization_filtered_area2').empty();
        if ($('.exp-drop4').length > 0) {
          $('#data_list_set2').remove();

          ul_string = '<ul class="m-0" id="data_list_set2">';
          $.each(result.gd_data_lists, (k, v) => {
            if (result.gd_checked_data_lists.indexOf(parseInt(k)) >= 0) {
              var check = 'checked="checked"';
            } else {
              var check = ' ';
            }
            const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set2_ids[]" class="datalistcomgrp${k} datalistcomgrp-checkbox1 inputcomgrp-checkbox-data"${check}value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
            ul_string = ul_string.concat(li_element);
          });
          ul_string.concat('</ul>');
          $('#datalist_set2_append').append(ul_string);
        }
        $('#datalist2_filtered_area').empty();
        const selectedAllCheckedData2Tag = document.querySelectorAll('.datalistcomgrp-checkbox1:checked');
        showCheckedDataAsListsDetails1(selectedAllCheckedData2Tag, 'datalistcomgrp', 'Data list', 2);
      });
  });

  // when one clear filter is used in page

  $('.clearSelected1').on('click', () => {
    $('.input-checkbox-data').prop('checked', false);
    $(".select-all-check").prop('checked', false);
    $('.selected-filter-boxes1').remove();
  });

  const NoIconDropdownLists = document.querySelectorAll('.no-icon-dropdown .dropdown-item');
  if (NoIconDropdownLists != null) {
    for (i = 0; i < NoIconDropdownLists.length; i++) {
      NoIconDropdownLists[i].addEventListener('click', (event) => { selectItems(event); }, false);
    }
  }

  function selectItems(event) {
    const selectedText = event.target.innerText;
    const dropdownValue = event.target.getAttribute('data-value');
    event.target.closest('.dropdown').querySelector('.dropdown-toggle').value = dropdownValue;
    event.target.closest('.dropdown').querySelector('.dropdown-toggle').innerText = selectedText;
  }

  // ----------------------checkbox group-------------
  $('.dim-filter-checkbox').on('click', function () {
    // in the handler, 'this' refers to the box clicked on
    const $box = $(this);
    if ($box.is(':checked')) {
      // the name of the box is retrieved using the .attr() method
      // as it is assumed and expected to be immutable
      const group = `input:checkbox[data-name='${$box.attr('data-name')}']`;
      // the checked state of the group/box on the other hand will change
      // and the current value is retrieved using .prop() method
      $(group).prop('checked', false);
      $box.prop('checked', true);
    } else {
      $box.prop('checked', false);
    }
  });
});


// var expandedCheckbox = false; /// dropdown checkbox variable
// This is for login page
const loginBtnSubmit = document.querySelector('#login-id'); // btn click in login page
const inputLoginEmailId = document.querySelector('#inputEmail'); // assign value in input tag
const inputLoginPassword = document.querySelector('#inputPassword');// assign password value
// const inputLoginCheckbox = document.querySelector('#termsAndConditionCheckbox');// save ckeckbox checked
const inputforgetEmailId = document.querySelector('#forgetEmailId');
const errorMsg = document.querySelector('.login-error-msg');// for error msg
const getAllErrorMsg = document.getElementsByClassName('display-error-remove'); // get all shown error msgs
// This for when login page is submit btn clicked
if (loginBtnSubmit !== null) {
  loginBtnSubmit.addEventListener('click', validateAndSaveLogin);
}


function validateAndSaveLogin() {
  event.preventDefault();
  if (inputLoginEmailId.value != '') {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,4}))$/;
    if (inputLoginEmailId.value.match(validRegex)) {
      if (inputLoginPassword.value !== '') {
        if ((inputLoginPassword.value.length) >= 0) {
          window.location.href = './dashBoard.html';
        } else {
          errorMsg.classList.add('login-error-show');
          errorMsg.textContent = 'The email address or password that you provided does not match our records.';
        }
      } else {
        errorMsg.classList.add('login-error-show');
        errorMsg.textContent = 'The email address or password that you provided does not match our records.';
      }
    } else {
      errorMsg.classList.add('login-error-show');
      errorMsg.textContent = 'The email address or password that you provided does not match our records.';
    }
  } else {
    errorMsg.classList.add('login-error-show');
    errorMsg.textContent = 'The email address or password that you provided does not match our records.';
  }
}

// remove error msg when typing in input tag login
if (getAllErrorMsg != null) {
  for (var i = 0; i < getAllErrorMsg.length; i++) {
    getAllErrorMsg[i].addEventListener('keypress', clearErrorMsg, false);
  }
}

function clearErrorMsg() {
  errorMsg.classList.remove('login-error-show');
  errorMsg.textContent = '';
}
// validating the forgot password page
const submitEmail = document.getElementById('forgetPasswordBtn');

if (submitEmail !== null) {
  submitEmail.addEventListener('click', () => { submitEmailForPassword(); }, false);
}
function submitEmailForPassword() {
  event.preventDefault();
  const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,4}))$/;
  if (inputforgetEmailId.value.match(validRegex)) {
    sessionStorage.setItem('forgetPasswordMsg', 'If your e-mail exists on our database, you will receive a password recovery link on your email');
    window.location.href = './login.html';
  } else {
    errorMsg.classList.add('login-error-show');
    errorMsg.textContent = 'The email address or password that you provided does not match our records.';
  }
}


// show/hide password in login page

const loginPasswordInput = document.getElementById('login-password-icon');
if (loginPasswordInput != null) {
  loginPasswordInput.addEventListener('click', () => { showHidePasswordContent('login-password-icon', 'inputPassword'); }, false);
}
const loginPasswordIdNew = document.getElementById('login-new-password-img');
if (loginPasswordIdNew != null) {
  loginPasswordIdNew.addEventListener('click', () => { showHidePasswordContent('login-new-password-img', 'forgetInputPassword'); }, false);
}
const loginPasswordIdConfirm = document.getElementById('login-confirm-password-img');
if (loginPasswordIdConfirm != null) {
  loginPasswordIdConfirm.addEventListener('click', () => { showHidePasswordContent('login-confirm-password-img', 'forgetConfirmInputPassword'); }, false);
}

const loginPasswordIdCurrent = document.getElementById('login-current-password-img');
if (loginPasswordIdCurrent != null) {
  loginPasswordIdCurrent.addEventListener('click', () => { showHidePasswordContent('login-current-password-img', 'forgetCurrentPassword'); }, false);
}


function showHidePasswordContent(passwordIconId, inputPasswordId) {
  const x = document.getElementById(inputPasswordId);
  if (x.getAttribute('type') === 'password') {
    x.type = 'text';
    document.getElementById(passwordIconId).src = './images/login-content/password-icon-svg.svg';
  } else {
    x.type = 'password';
    document.getElementById(passwordIconId).src = './images/login-content/password-icon.svg';
  }
}

// validate reset password in the page
const resetPasswordSubmit = document.querySelector('#resetPasswordBtnUpdate');
if (resetPasswordSubmit !== null) {
  resetPasswordSubmit.addEventListener('click', () => { resetPasswordSubmitData(); }, false);
}

function resetPasswordSubmitData() {
  event.preventDefault();
  let countLogin = 0;
  const hasNumber = /\d/;
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,4}))$/;
  if (document.getElementById('forgetInputEmailId').value.match(validRegex)) {
    const inputNewPassword = document.getElementById('forgetInputPassword').value;
    const confirmPassword = document.getElementById('forgetConfirmInputPassword').value;
    const currentPassoword = document.getElementById('forgetCurrentPassword').value;

    if ((inputNewPassword == ' ') || (confirmPassword == ' ') || (currentPassoword == ' ')) {
      errorMsg.classList.add('login-error-show');
      errorMsg.textContent = 'Password starting with space not allowed';
    } else if (inputNewPassword.length < 0 || confirmPassword.length <= 0 || currentPassoword.length <= 0) {
      errorMsg.classList.add('login-error-show');
      errorMsg.innerHTML = 'Password too weak! It should inclde atleast 1 uppercase, 1 lowercase, 1 digit and 1 special character<br/>'
            + 'Password is too short (minimum is 14 characters)<br/>'
            + "Password confirmation doesn't match confirmation<br/>"
            + 'Current password is invalid';
    } else if (hasNumber.test(inputNewPassword) == false) {
      errorMsg.classList.add('login-error-show');
      errorMsg.innerHTML = 'Password too weak! It should inclde atleast 1 uppercase, 1 lowercase, 1 digit and 1 special character<br/>'
            + 'Password is too short (minimum is 14 characters)<br/>'
            + "Password confirmation doesn't match confirmation<br/>"
            + 'Current password is invalid';
    } else if (format.test(inputNewPassword) == false) {
      errorMsg.classList.add('login-error-show');
      errorMsg.innerHTML = 'Password too weak! It should inclde atleast 1 uppercase, 1 lowercase, 1 digit and 1 special character<br/>'
            + 'Password is too short (minimum is 14 characters)<br/>'
            + "Password confirmation doesn't match confirmation<br/>"
            + 'Current password is invalid';
    } else if (inputNewPassword == confirmPassword) {
      const removeNumbersInString = inputNewPassword.replace(/[0-9]/g, '');
      for (let i = 0; i < removeNumbersInString.length; i++) {
        if (removeNumbersInString[i].toUpperCase() == removeNumbersInString[i]) {
          countLogin = 1;
          break;
        }
      }

      if (countLogin == 1) {
        sessionStorage.setItem('resetPasswordMsg', 'Your password has been reset. You are now logged in with your new password');
        window.location.href = './dashBoard.html';
      } else {
        errorMsg.classList.add('login-error-show');
        errorMsg.innerHTML = 'Password too weak! It should inclde atleast 1 uppercase, 1 lowercase, 1 digit and 1 special character<br/>'
                + 'Password is too short (minimum is 14 characters)<br/>'
                + "Password confirmation doesn't match confirmation<br/>"
                + 'Current password is invalid';
      }
      // sessionStorage.setItem("resetPasswordMsg", "Your password has been reset. You are now logged in with your new password");
      // window.location.href="./dashBoard.html";
    } else {
      errorMsg.classList.add('login-error-show');
      errorMsg.innerHTML = 'Password too weak! It should inclde atleast 1 uppercase, 1 lowercase, 1 digit and 1 special character<br/>'
            + 'Password is too short (minimum is 14 characters)<br/>'
            + "Password confirmation doesn't match confirmation<br/>"
            + 'Current password is invalid';
    }
  } else {
    errorMsg.classList.add('login-error-show');
    errorMsg.textContent = 'The email address or password that you provided does not match our records.';
  }
}


// search lists in dropdown


$('.search-value').on('keyup change', function () {
  $(this).parents('.filter-dropdown-container').find('.filter-select-content').addClass('show');
  const input = $(this).val();
  const filter = input.toUpperCase();
  const li = $(this).parents('.filter-dropdown-container').find('li');


  let countDisplayNone = 0;

  for (i = 0; i < li.length; i++) {
    const txtValue = li[i].innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = '';
    } else {
      li[i].style.display = 'none';
      countDisplayNone += 1;
    }
  }


  if (li.length == countDisplayNone) {
    $(this).parents('.filter-dropdown-container').find('.filter-select-content').removeClass('show');
  }
});

// header dropdown

$('.rep-drop').on('click', function () {
  if ($(this).parents('.dropdown').hasClass('show')) {
    $(this).parents('.dropdown').toggleClass('show');
  } else {
    $('.dropdown').removeClass('show');
    $(this).parents('.dropdown').addClass('show');
  }
});

const withIconDropdownLists = document.querySelectorAll('.with-icon-dropdown .dropdown-item');
if (withIconDropdownLists != null) {
  for (i = 0; i < withIconDropdownLists.length; i++) {
    withIconDropdownLists[i].addEventListener('click', () => { selectItems(event); }, false);
  }
}


$('.select-dropdown-lists-show .dropdown-item').on('click', function () {
  const selText = $(this).text();
  $(this).parents('.select-dropdown-lists-show').find('.show-selected').text(selText);
});


// this is for left side menu drop upa and down parent element
// $(".menu-page .nav-link").click(function (){
//     if($(this).hasClass('show')) {
//         $(this).removeClass("show");
//     }
//     else {
//         $(".nav-link").removeClass("show");
//         $(this).addClass("show");
//     }
// });

// this is for left side menu drop upa and down child element

// $(".third-sub-menu-link").click(function (){
//     if($(this).hasClass('show')) {
//         $(this).removeClass("show");
//     }
//     else {
//         $(".third-sub-menu-link ").removeClass("show");
//         $(this).addClass("show");
//     }
// });


// this is for left side menu drop upa and down parent element
$('.menu-page .nav-link').click(function () {
  if ($(this).hasClass('show')) {
    $(this).removeClass('show');
    $('.third-sub-menu-link ').removeClass('show');
    $('.forth-sub-menu-link ').removeClass('show');
  } else {
    $('.nav-link').removeClass('show');
    $('.third-sub-menu-link ').removeClass('show');
    $('.forth-sub-menu-link ').removeClass('show');
    $(this).addClass('show');
  }
});

// this is for left side menu drop upa and down child element

$('.third-sub-menu-link').click(function () {
  if ($(this).hasClass('show')) {
    $(this).removeClass('show');
  } else {
    $('.third-sub-menu-link ').removeClass('show');
    $(this).addClass('show');
  }
});

$('.nav-arrow').click(function () {
  $(this).parent('.nav-link').toggleClass('show');
  if ($(this).parent('.menu-link-nav').hasClass('third-sub-menu-link')) {
    $(this).parent('.third-sub-menu-link').toggleClass('show');
  }
  return false;
});


// click out side remove popup or dropdown in every page


$(document).click((event) => {
  if (!$('.filter-dropdown-container').has(event.target).length) {
    $('.filter-select-content').removeClass('show');
  }
  if (!$('.type-dropdown').has(event.target).length) {
    $('.type-search-content').addClass('hideDropdown');
  }
  if (!$('.exp-dropdown-details').has(event.target).length) {
    $('.exp-dropdown-details').removeClass('show');
  }
  if (!$('.rep-drop-content').has(event.target).length) {
    $('.rep-drop-content').removeClass('show');
  }
});


$(document).ready(() => {
  // show error msg in login / forgot and reset page

  if (sessionStorage.getItem('forgetPasswordMsg') != '' && sessionStorage.getItem('forgetPasswordMsg') != null) {
    $('.login-error-msg').addClass('login-error-show');
    $('.login-error-msg').text(sessionStorage.getItem('forgetPasswordMsg'));
    sessionStorage.setItem('forgetPasswordMsg', '');
  }

  // show success page after reset in dashboard

  if (sessionStorage.getItem('resetPasswordMsg') != '' && sessionStorage.getItem('resetPasswordMsg') != null) {
    $('.alert-success-msg .alert-content').addClass('show');
    $('.success-msg-alert').text(sessionStorage.getItem('resetPasswordMsg'));
    sessionStorage.setItem('resetPasswordMsg', '');
  }


  $('#view-search-table-block2').DataTable({
    dom: '<"top">t<"bottom"pi><"clear">',
    ordering: false,
    autoWidth: false,

    language: {
      info: 'Page _PAGE_ of _PAGES_',

      paginate: {
        previous: '‹',
        next: '›',
      },
      aria: {
        paginate: {
          previous: 'Previous',
          next: 'Next',
        },
      },
    },

    fnDrawCallback() {
      $('[data-toggle="tooltipOrg"]').tooltip({
        placement: 'top',
        boundary: 'window',
        delay: 100,
        customClass: 'org-tooltip',
      });
      const no_data = $('#view-search-table-block tr .dataTables_empty').text();

      if (no_data === 'No data available in table') {
        $('.dataTables_paginate').hide();
        $('.dataTables_info').hide();
      }
    },

    pageLength: 5,

    order: [[1, 'asc']],
  });

  $('#view-search-table-block').DataTable({
    dom: '<"top">t<"bottom"pi><"clear">',
    ordering: false,
    autoWidth: false,

    language: {
      info: 'Page _PAGE_ of _PAGES_',

      paginate: {
        previous: '‹',
        next: '›',
      },
      aria: {
        paginate: {
          previous: 'Previous',
          next: 'Next',
        },
      },
    },

    fnDrawCallback() {
      $('[data-toggle="tooltipOrg"]').tooltip({
        placement: 'top',
        boundary: 'window',
        delay: 100,
        customClass: 'org-tooltip',
      });
      const no_data = $('#view-search-table-block tr .dataTables_empty').text();

      if (no_data === 'No data available in table') {
        $('.dataTables_paginate').hide();
        $('.dataTables_info').hide();
      }
    },

    pageLength: 5,

    order: [[1, 'asc']],
  });


  $.fn.DataTable.ext.pager.numbers_length = 5;
  $('#certificate-table-block').DataTable({
    dom: '<"top">rt<"bottom"pi><"clear">',
    ordering: false,
    searching: false,
    lengthChange: false,
    autoWidth: false,
    language: {
      info: 'Page _PAGE_ of _PAGES_',
      paginate: {
        previous: '‹',
        next: '›',
      },
      aria: {
        paginate: {
          previous: 'Previous',
          next: 'Next',
        },
      },
    },
    fnDrawCallback() {
      const no_data = $('#certificate-table-block tr .dataTables_empty').text();

      if (no_data === 'No data available in table') {
        $('.dataTables_paginate').hide();
        $('.dataTables_info').hide();
      }
    },

    pageLength: 5,

    order: [[1, 'asc']],
  });

  $('#table-block').DataTable({
    dom: '<"top">rt<"bottom"pi><"clear">',
    ordering: false,
    searching: false,
    lengthChange: false,
    autoWidth: false,
    language: {
      info: 'Page _PAGE_ of _PAGES_',
      paginate: {
        previous: '‹',
        next: '›',
      },
      aria: {
        paginate: {
          previous: 'Previous',
          next: 'Next',
        },
      },
    },
    fnDrawCallback() {
      $('[data-toggle="tooltipGenTooltip"]').tooltip({
        placement: 'top',
        boundary: 'window',
        delay: 100,
        customClass: 'org-tooltip-gen',
      });
      const no_data = $('#table-block tr .dataTables_empty').text();

      if (no_data === 'No data available in table') {
        $('.dataTables_paginate').hide();
        $('.dataTables_info').hide();
      }
    },

    pageLength: 5,

    order: [[1, 'asc']],
  });


  // end of table functionality
});

// dashboard dropdown show/hide functionality

$('.filter-select-dropdown').on('click', function () {
  showdropdownfilters(this);
});

function showdropdownfilters(parameter) {
  if ($(parameter).parents('.filter-dropdown-container').find('.filter-select-content').hasClass('show')) {
    $(parameter).parents('.filter-dropdown-container').find('.filter-select-content').toggleClass('show');
  } else {
    $('.filter-select-content').removeClass('show');
    $(parameter).parents('.filter-dropdown-container').find('.filter-select-content').addClass('show');
  }
}


// get all checked lists in checkbox dropdown

function loadCheckedData(whichCategory, whichDropdownName, findCheckedCategoryLists, oneCaseBox) {
  const selectedAllCheckedDataTag = document.querySelectorAll(`.${findCheckedCategoryLists}:checked`);
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, whichCategory, whichDropdownName, oneCaseBox);
}

// calling each dropdown

$('.category-checkbox').on('change', () => {
  loadCheckedData('category', 'Survey Question', 'category-checkbox', 1);
});

$('.level-checkbox').on('change', () => {
  loadCheckedData('level', 'Level of responsibility', 'level-checkbox', 1);
});

$('.care-checkbox').on('change', () => {
  loadCheckedData('care', 'Care responsibility', 'care-checkbox', 1);
});

$('.race-checkbox').on('change', () => {
  loadCheckedData('race', 'Race/ethnicity', 'race-checkbox', 1);
});

$('.age-checkbox').on('change', () => {
  loadCheckedData('age', 'Age', 'age-checkbox', 1);
});

$('.lgbtq-checkbox').on('change', () => {
  loadCheckedData('lgbtq', 'LGBTQ', 'lgbtq-checkbox', 1);
});

$('.disability-checkbox').on('change', () => {
  loadCheckedData('disability', 'Disability', 'disability-checkbox', 1);
});

$('.nationality-checkbox').on('change', () => {
  loadCheckedData('nationality', 'Nationality', 'nationality-checkbox', 1);
});

$('.transgender-checkbox').on('change', () => {
  loadCheckedData('transgender', 'Transgender', 'transgender-checkbox', 1);
});

// calling dropdown radio button

$('.view-graph-checkbox').on('change', () => {
  loadCheckedData('view-graph', 'View Graph', 'view-graph-checkbox', 1);
});

// end here radio button dropdown

$('.org-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.org-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'org', 'Organization', 1);
});

$('.period-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.period-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'period', 'Reference Period', 1);
});

$('.country-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.country-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'country', 'Country', 1);
});

$('.organizationgroup-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationgroup-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'organizationgroup', 'Organization Group', 1);
});

$('.organizationname-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationname-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'organizationname', 'Organization Name', 1);
});

$('.region-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.region-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'region', 'Region', 1);
});

$('.datalist-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.datalist-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'datalist', 'Data list', 1);
});

$('.organizationgroupcomgrp-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationgroupcomgrp-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'organizationgroupcomgrp', 'Organization Group', 2);
});
$('.organizationnamecomgrp-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationnamecomgrp-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'organizationnamecomgrp', 'Organization Name', 2);
});
$('.regioncomgrp-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.regioncomgrp-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'regioncomgrp', 'Region', 2);
});
$('.datalistcomgrp-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.datalistcomgrp-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'datalistcomgrp', 'Data list', 2);
});

$('.industry-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.industry-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'industry', 'Industry', 1);
});

$('.status-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.status-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'status', 'Status', 1);
});

$('.org-group-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.org-group-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'org-group', 'Organization Group', 1);
});

$('.framework-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.framework-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'framework', 'Framework', 1);
});

$('.org-size-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.org-size-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'org-size', 'Organization Size', 1);
});

$('.dimensions-checkbox').on('change', () => {
  const selectedAllCheckedDataTag = document.querySelectorAll('.dimensions-checkbox:checked');
  showCheckedDataAsListsDetails(selectedAllCheckedDataTag, 'dimensions', 'Dimensions', 1);
});

// functionality to lists all selected checked dropdown one in div - benchmark

function showCheckedDataAsListsBenchmarkDetails(valueChecked, whichdropcheck, groupname, whichSideNumber) {
  let allIdAndValueChecked = {};
  allIdAndValueChecked.Value = [];
  allIdAndValueChecked.ClassName = [];
  for (var i = 0; i < valueChecked.length; i++) {
    allIdAndValueChecked.Value.push(valueChecked[i].getAttribute('data-value').replace(/[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';'));
    const whichDropdown = valueChecked[i].className.toLowerCase().indexOf(whichdropcheck);
    const getUniqueClassname = valueChecked[i].className.slice(whichDropdown);
    allIdAndValueChecked.ClassName.push(getUniqueClassname.split(' ')[0]);
  }

  let addTagChecked = '';
  let addTagAllChecked = '';

  $(`.${whichdropcheck}-checkbox-show`).remove();
  for (i = 0; i < allIdAndValueChecked.ClassName.length; i++) {
    addTagChecked = `${addTagChecked}<span id=${allIdAndValueChecked.ClassName[i]} class='font-onezero-light selected-filter'>${allIdAndValueChecked.Value[i]}<a class='close-selected-tag'>&times;</a></span>`;
  }

  addTagAllChecked = `<p class='font-onetwo-semibold selected-filter-boxes${whichSideNumber} ${whichdropcheck}-checkbox-show'> ${groupname} &nbsp;${addTagChecked}</p>`;
  $(`.${whichdropcheck}-checked-data`).append(addTagAllChecked);
  if (allIdAndValueChecked.ClassName.length < 1) {
    $(`.${whichdropcheck}-checkbox-show`).remove();
  }
  allIdAndValueChecked = [];
}

function showOrganisationGrpBasedDetails() {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationgroup-checkbox1:checked');
  const regionTag = document.querySelectorAll('.region-checkbox1:checked');
  const companyTag = document.querySelectorAll('.organizationname-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(selectedAllCheckedDataTag).each(function () {
    group.push($(this).val());
  });
  $(regionTag).each(function () {
    region.push($(this).val());
  });
  $(companyTag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop3').length > 0) {
        $('#region_set1').remove();
        ul_string = `<ul class="m-0" id="region_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_regions, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="region_set1[]" class="region${k} region-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#region_set1_append').append(ul_string);
      }
      $('#region_filtered_area').empty();
      if ($('.exp-drop2').length > 0) {
        $('#company_set1').remove();

        ul_string = `<ul class="m-0" id="company_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_companies, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set1[]" class="organizationname${k} organizationname-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#company_set1_append').append(ul_string);
      }
      $('#organization_filtered_area').empty();
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set1').remove();

        ul_string = `<ul class="m-0" id="data_list_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set1_ids[]" class="datalist${k} datalist-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set1_append').append(ul_string);
      }
      $('#datalist1_filtered_area').empty();
    });

  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedDataTag, 'organizationgroup', 'Organization Group', 1);
}

function showRegionBasedDetails() {
  const selectedAllCheckedDataTag = document.querySelectorAll('.region-checkbox1:checked');
  const groupTag = document.querySelectorAll('.organizationgroup-checkbox1:checked');
  const companyTag = document.querySelectorAll('.organizationname-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(groupTag).each(function () {
    group.push($(this).val());
  });
  $(selectedAllCheckedDataTag).each(function () {
    region.push($(this).val());
  });
  $(companyTag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop2').length > 0) {
        $('#company_set1').remove();
        ul_string = `<ul class="m-0" id="company_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_companies, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set1[]" class="organizationname${k} organizationname-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#company_set1_append').append(ul_string);
      }
      $('#organization_filtered_area').empty();
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set1').remove();

        ul_string = `<ul class="m-0" id="data_list_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set1_ids[]" class="datalist${k} datalist-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set1_append').append(ul_string);
      }
      $('#datalist1_filtered_area').empty();
    });

  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedDataTag, 'region', 'Region', 1);
}

function showCompanyBasedDetails() {
  const selectedAllCheckedDataTag = document.querySelectorAll('.organizationname-checkbox1:checked');
  const groupTag = document.querySelectorAll('.organizationgroup-checkbox1:checked');
  const regionTag = document.querySelectorAll('.region-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(groupTag).each(function () {
    group.push($(this).val());
  });
  $(regionTag).each(function () {
    region.push($(this).val());
  });
  $(selectedAllCheckedDataTag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set1').remove();

        ul_string =`<ul class="m-0" id="data_list_set1">
        <li><label class="exp-label">
          <input type="checkbox" class="select-all-check" value="">
          <span class="exp-checkbox"></span>
          <span class="font-onetwo-regular exp-checkbox-text">Select all</span>
        </label></li>`;
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set1_ids[]" class="datalist${k} datalist-checkbox1 input-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set1_append').append(ul_string);
      }
      $('#datalist1_filtered_area').empty();
    });

  showCheckedDataAsListsBenchmarkDetails(selectedAllCheckedDataTag, 'organizationname', 'Organization Name', 1);
}

function showOrganisationGrp2BasedDetails() {
  const selectedAllCheckedDataGroup2Tag = document.querySelectorAll('.organizationgroupcomgrp-checkbox1:checked');
  const regionTag = document.querySelectorAll('.regioncomgrp-checkbox1:checked');
  const companyTag = document.querySelectorAll('.organizationnamecomgrp-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(selectedAllCheckedDataGroup2Tag).each(function () {
    group.push($(this).val());
  });
  $(regionTag).each(function () {
    region.push($(this).val());
  });
  $(companyTag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop3').length > 0) {
        $('#region_set2').remove();
        ul_string = '<ul class="m-0" id="region_set2">';
        $.each(result.gd_regions, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="region_set2[]" class="regioncomgrp${k} regioncomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#region_set2_append').append(ul_string);
      }
      $('#region_filtered_area2').empty();
      if ($('.exp-drop2').length > 0) {
        $('#company_set2').remove();

        ul_string = '<ul class="m-0" id="company_set2">';
        $.each(result.gd_companies, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set2[]" class="organizationnamecomgrp${k} organizationnamecomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#company_set2_append').append(ul_string);
      }
      $('#organization_filtered_area2').empty();
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set2').remove();

        ul_string = '<ul class="m-0" id="data_list_set2">';
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set2_ids[]" class="datalistcomgrp${k} datalistcomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set2_append').append(ul_string);
      }
      $('#datalist2_filtered_area').empty();
    });
  showCheckedDataAsListsDetails1(selectedAllCheckedDataGroup2Tag, 'organizationgroupcomgrp', 'Organization Group', 2);
}

function showRegion2BasedDetails() {
  const selectedAllCheckedDataRegion2Tag = document.querySelectorAll('.regioncomgrp-checkbox1:checked');
  const companygrpTag = document.querySelectorAll('.organizationgroupcomgrp-checkbox1:checked');
  const companyTag = document.querySelectorAll('.organizationnamecomgrp-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(companygrpTag).each(function () {
    group.push($(this).val());
  });
  $(selectedAllCheckedDataRegion2Tag).each(function () {
    region.push($(this).val());
  });
  $(companyTag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop2').length > 0) {
        $('#company_set2').remove();

        ul_string = '<ul class="m-0" id="company_set2">';
        $.each(result.gd_companies, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="company_set2[]" class="organizationnamecomgrp${k} organizationnamecomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#company_set2_append').append(ul_string);
      }
      $('#organization_filtered_area2').empty();
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set2').remove();

        ul_string = '<ul class="m-0" id="data_list_set2">';
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set2_ids[]" class="datalistcomgrp${k} datalistcomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set2_append').append(ul_string);
      }
      $('#datalist2_filtered_area').empty();
    });
  showCheckedDataAsListsDetails1(selectedAllCheckedDataRegion2Tag, 'regioncomgrp', 'Region', 2);
}

function showCompany2BasedDetails() {
  const selectedAllCheckedCompany2Tag = document.querySelectorAll('.organizationnamecomgrp-checkbox1:checked');
  const region2Tag = document.querySelectorAll('.regioncomgrp-checkbox1:checked');
  const companygrpTag = document.querySelectorAll('.organizationgroupcomgrp-checkbox1:checked');
  group = [];
  region = [];
  company = [];
  $(companygrpTag).each(function () {
    group.push($(this).val());
  });
  $(region2Tag).each(function () {
    region.push($(this).val());
  });
  $(selectedAllCheckedCompany2Tag).each(function () {
    company.push($(this).val());
  });
  $.get('/ajax/gd_filters', { company_group_ids: group, region_ids: region, company_ids: company },
    (result) => {
      if ($('.exp-drop4').length > 0) {
        $('#data_list_set2').remove();

        ul_string = '<ul class="m-0" id="data_list_set2">';
        $.each(result.gd_data_lists, (k, v) => {
          const li_element = `<li><label class="exp-label">\
            <input type="checkbox" name="dt_set2_ids[]" class="datalistcomgrp${k} datalistcomgrp-checkbox1 inputcomgrp-checkbox-data" value="${k}" data-value="${v}">\
            <span class="exp-checkbox"></span>\
            <span class="font-onetwo-regular exp-checkbox-text">${v}</span>\
            </label></li>`;
          ul_string = ul_string.concat(li_element);
        });
        ul_string.concat('</ul>');
        $('#datalist_set2_append').append(ul_string);
      }
      $('#datalist2_filtered_area').empty();
    });
  showCheckedDataAsListsDetails1(selectedAllCheckedCompany2Tag, 'organizationnamecomgrp', 'Organization Name', 2);
}
// functionality to lists all selected checked dropdown one in div

function showCheckedDataAsListsDetails(valueChecked, whichdropcheck, groupname, whichSideNumber) {
  let allIdAndValueChecked = {};
  allIdAndValueChecked.Value = [];
  allIdAndValueChecked.ClassName = [];
  for (var i = 0; i < valueChecked.length; i++) {
    allIdAndValueChecked.Value.push(valueChecked[i].value);
    const whichDropdown = valueChecked[i].className.toLowerCase().indexOf(whichdropcheck);
    const getUniqueClassname = valueChecked[i].className.slice(whichDropdown);
    allIdAndValueChecked.ClassName.push(getUniqueClassname.split(' ')[0]);
  }

  let addTagChecked = '';
  let addTagAllChecked = '';

  $(`.${whichdropcheck}-checkbox-show`).remove();
  for (i = 0; i < allIdAndValueChecked.ClassName.length; i++) {
    addTagChecked = `${addTagChecked}<span id=${allIdAndValueChecked.ClassName[i]} class='font-onezero-light selected-filter'>${allIdAndValueChecked.Value[i]}<a class='close-selected-tag'>&times;</a></span>`;
  }

  addTagAllChecked = `<p class='font-onetwo-semibold selected-filter-boxes${whichSideNumber} ${whichdropcheck}-checkbox-show'> ${groupname} &nbsp;${addTagChecked}</p>`;
  $(`.${whichdropcheck}-checked-data`).append(addTagAllChecked);
  if (allIdAndValueChecked.ClassName.length < 1) {
    $(`.${whichdropcheck}-checkbox-show`).remove();
  }
  allIdAndValueChecked = [];
}

// functionality to lists all selected checked dropdown one in div

function showCheckedDataAsListsDetails1(valueChecked, whichdropcheck, groupname, whichSideNumber) {
  let allIdAndValueChecked = {};
  allIdAndValueChecked.Value = [];
  allIdAndValueChecked.ClassName = [];
  for (var i = 0; i < valueChecked.length; i++) {
    allIdAndValueChecked.Value.push(valueChecked[i].getAttribute('data-value').replace(/[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';'));
    const whichDropdown = valueChecked[i].className.toLowerCase().indexOf(whichdropcheck);
    const getUniqueClassname = valueChecked[i].className.slice(whichDropdown);
    allIdAndValueChecked.ClassName.push(getUniqueClassname.split(' ')[0]);
  }

  let addTagChecked = '';
  let addTagAllChecked = '';

  $(`.${whichdropcheck}-checkbox-show`).remove();
  for (i = 0; i < allIdAndValueChecked.ClassName.length; i++) {
    addTagChecked = `${addTagChecked}<span id=${allIdAndValueChecked.ClassName[i]} class='font-onezero-light selected-filter'>${allIdAndValueChecked.Value[i]}<a class='close-selected-tag'>&times;</a></span>`;
  }

  addTagAllChecked = `<p class='font-onetwo-semibold selected-filter-boxes${whichSideNumber} ${whichdropcheck}-checkbox-show'> ${groupname} &nbsp;${addTagChecked}</p>`;
  $(`.${whichdropcheck}-checked-data`).append(addTagAllChecked);
  if (allIdAndValueChecked.ClassName.length < 1) {
    $(`.${whichdropcheck}-checkbox-show`).remove();
  }
  allIdAndValueChecked = [];
}

// clears all the selected filter shown

$(document).on('click', '.close-selected-tag', function () {
  const idToUncheck = $(this).parent().attr('id');
  const selected_filtered_area = $(this).parent().parent().parent()
    .attr('id');
  $(`.${idToUncheck}`).prop('checked', false);
  if($("."+idToUncheck).parents('.exp-dropdown-details').find('.hide').length<=0)
    {
        $(".select-all-check").prop('checked', false);
        let s=$(this).parent().parent().find('.selected-filter')
        if(s.length==1)
        {
            $(this).parent().parent().remove();
        }
        else {
            $(this).parent().remove();
        }
    }
  const s = $(this).parent().parent().find('.selected-filter');
  if (s.length == 1) {
    $(this).parent().parent().remove();
  } else {
    $(this).parent().remove();
  }

  if (selected_filtered_area == 'region_filtered_area') {
    showRegionBasedDetails();
  }
  if (selected_filtered_area == 'organisation-group_filtered_area') {
    showOrganisationGrpBasedDetails();
  }
  if (selected_filtered_area == 'organization_filtered_area') {
    showCompanyBasedDetails();
  }

  if (selected_filtered_area == 'region_filtered_area2') {
    showRegion2BasedDetails();
  }
  if (selected_filtered_area == 'organisation-group_filtered_area2') {
    showOrganisationGrp2BasedDetails();
  }
  if (selected_filtered_area == 'organization_filtered_area2') {
    showCompany2BasedDetails();
  }
});

// when one clear filter is used in page

$('.clearSelected1').on('click', () => {
  $('.input-checkbox-data').prop('checked', false);
  $('.selected-filter-boxes1').remove();
});

// when two clear filter is used in page along with above one

$('.clearSelected2').on('click', () => {
  $('.inputcomgrp-checkbox-data').prop('checked', false);
  $('.selected-filter-boxes2').remove();
});

// equal pay checkbox open functionality

$('.optionShowAdditionaljs').on('change', () => {
  if ($('.optionShowAdditionaljs').is(':checked')) {
    $('.estimate-additional').addClass('show');
  }
});

$('.optionShowAdditionalNotjs').on('change', () => {
  $('.estimate-additional').removeClass('show');
});


// file upload functionality

$(document).on('change', '.upload-file', function () {
  const fileName = $(this).val().split('\\').pop();
  $('.show-uploaded-file').addClass('selected').html(fileName);
});


// Search and type dropdown

$('.type-search-content .dropdown-value').on('click', function () {
  const selText = $(this).text();
  $(this).parents('.type-search-dropdown').find('.input-manage').val(selText);
  $(this).parents('.type-search-dropdown').find('.type-search-content').addClass('hideDropdown');
});

// show search type dropdown when click

$('.type-dropdown').on('click', function () {
  $('.type-search-content').addClass('hideDropdown');
  $(this).parents('.type-search-dropdown').find('.type-search-content').removeClass('hideDropdown');
});

$('.type-dropdown .input-manage').on('keyup', function () {
  let filter; let li; let i; let
    txtValue;
  const s = $(this).attr('id');
  filter = $(`#${s}`).val();
  li = $('.type-search-content .dropdown-value');
  // Loop through all list items, and hide those who don't match the search query
  for (i = 0; i < li.length; i++) {
    txtValue = li[i].innerHTML;
    const t = txtValue.toUpperCase().indexOf(filter.toUpperCase());
    if (t > -1) {
      li[i].style.display = '';
    } else {
      li[i].style.display = 'none';
    }
  }
});
